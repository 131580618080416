@import "~@angular/material/theming";
@import "./styles/color_palette.scss"; // contains custom palette
//@import "./app_theme.scss"; // contains app mixin
//config

/*
Name	CSS classes	Description
display-4	.mat-display-4	Large, one-off header, usually at the top of the page (e.g. a hero header).
display-3	.mat-display-3	Large, one-off header, usually at the top of the page (e.g. a hero header).
display-2	.mat-display-2	Large, one-off header, usually at the top of the page (e.g. a hero header).
display-1	.mat-display-1	Large, one-off header, usually at the top of the page (e.g. a hero header).
headline	.mat-h1, .mat-headline	Section heading corresponding to the <h1> tag.
title	.mat-h2, .mat-title	Section heading corresponding to the <h2> tag.
subheading-2	.mat-h3, .mat-subheading-2	Section heading corresponding to the <h3> tag.
subheading-1	.mat-h4, .mat-subheading-1	Section heading corresponding to the <h4> tag.
body-1	.mat-body, .mat-body-1	Base body text.
body-2	.mat-body-strong, .mat-body-2	Bolder body text.
caption	.mat-small, .mat-caption	Smaller body and hint text.
button	None. Used only in components.	Buttons and anchors.
input	None. Used only in components.	Form input fields.
 */
$app-typography: mat-typography-config(
  $font-family: "Titillium Web, sans-serif, Roboto, monospace",
  $display-4: mat-typography-level(112px, 112px, 300),
  $display-3: mat-typography-level(56px, 56px, 400),
  $display-2: mat-typography-level(45px, 48px, 400),
  $display-1: mat-typography-level(34px, 40px, 400),
  $headline: mat-typography-level(24px, 32px, 400),
  $title: mat-typography-level(22px, 32px, 600),
  $subheading-2: mat-typography-level(22px, 28px, 600),
  $subheading-1: mat-typography-level(18px, 24px, 500),
  $body-2: mat-typography-level(14px, 24px, 500),
  $body-1: mat-typography-level(14px, 20px, 400),
  $caption: mat-typography-level(12px, 20px, 400),
  $button: mat-typography-level(14px, 14px, 500),
  // Line-height must be unit-less fraction of the font-size.
  $input: mat-typography-level(16px, 1.25, 400),
);

@include mat-core($app-typography);

$primary: mat-palette($security-orange, 600); // $deeppurple form color_palette
$accent: mat-palette($security-blue, 500); // $blue form color_palette
$warn: mat-palette($security-red, 500); // $red form color_palette
$theme: mat-light-theme($primary, $accent, $warn);
//@include app-theme($theme); //app mixin from app_theme

body,
html {
  margin: 0;
  //height: 100vh;
  font-size: mat-font-size($app-typography, body-1);
  font-family: mat-font-family($app-typography);
  line-height: mat-line-height($app-typography, body-1);
}

//this overrides styles of indigo-pink default
//@import "~@angular/material/theming"; //(1)

//config
//@include mat-core(); //(1)mixin common style

// $custom-primary: mat-palette($security-orange, 600); //* required
// $custom-accent: mat-palette($security-blue, 100); //* required
// $custom-warning: mat-palette($mat-lime); // optional

// Define a custom typography config that overrides the font-family as well as the
// `headlines` and `body-1` levels.
// $custom-typography: mat-typography-config(
//   $font-family: "Titillium Web, sans-serif, Roboto, monospace",
//   $headline: mat-typography-level(32px, 48px, 600),
//   $body-1: mat-typography-level(16px, 24px, 500),
//   $title: mat-typography-level(20px, 32px, 600),
//   $subheading-2: mat-typography-level(16px, 28px, 600),
// );

//custom-theme
// Override typography for all Angular Material, including mat-base-typography and all components.
//@include angular-material-typography($custom-typography);

$custom-theme: mat-light-theme($primary, $accent); // optional

//pass to mixing becouse itself variables dont do anything
@include angular-material-theme($custom-theme);

//alternative theme
$alt-primary: mat-palette($mat-yellow); //* required
$alt-accent: mat-palette($mat-grey, 200); //* required
$alt-warning: mat-palette($mat-lime); // optional

//$custom-theme: mat-light-theme($custom-primary, $custom-accent); // optional
$alt-theme: mat-dark-theme($alt-primary, $alt-accent); // optional
.alternative {
  //@include angular-material-theme($alt-theme);// all theme
  @include mat-button-theme($alt-theme);
}

//8fHxRZvEbtQ3eSwZA2db
