/* You can add global styles to this file, and also import other style files */
html,
body {
  margin: 0;
  font-family: "Titillium Web", sans-serif, Roboto, "Helvetica Neue", sans-serif;
}
h1,
h2,
h3,
h5,
h6 {
  margin: 8px 0;
  color: #2b0d61;
  font-weight: 600;
}
h4 {
  color: #7587ba;
}
button.mat-flat-button {
  padding: 0px 32px 0px 32px;
  text-transform: uppercase;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi4uL3N0eWxlcy5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBLDhFQUFBO0FBRUE7O0VBRUUsU0FBQTtFQUNBLDhFQUFBO0FBQUY7QUFHQTs7Ozs7RUFLRSxhQUFBO0VBQ0EsY0FBQTtFQUNBLGdCQUFBO0FBQUY7QUFHQTtFQUNFLGNBQUE7QUFBRjtBQUdBO0VBQ0UsMEJBQUE7RUFDQSx5QkFBQTtBQUFGIiwiZmlsZSI6InN0eWxlcy5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLyogWW91IGNhbiBhZGQgZ2xvYmFsIHN0eWxlcyB0byB0aGlzIGZpbGUsIGFuZCBhbHNvIGltcG9ydCBvdGhlciBzdHlsZSBmaWxlcyAqL1xuXG5odG1sLFxuYm9keSB7XG4gIG1hcmdpbjogMDtcbiAgZm9udC1mYW1pbHk6IFwiVGl0aWxsaXVtIFdlYlwiLCBzYW5zLXNlcmlmLCBSb2JvdG8sIFwiSGVsdmV0aWNhIE5ldWVcIiwgc2Fucy1zZXJpZjtcbn1cblxuaDEsXG5oMixcbmgzLFxuaDUsXG5oNiB7XG4gIG1hcmdpbjogOHB4IDA7XG4gIGNvbG9yOiAjMmIwZDYxO1xuICBmb250LXdlaWdodDogNjAwO1xufVxuXG5oNHtcbiAgY29sb3I6ICM3NTg3YmE7XG59XG5cbmJ1dHRvbi5tYXQtZmxhdC1idXR0b24ge1xuICBwYWRkaW5nOiAwcHggMzJweCAwcHggMzJweDtcbiAgdGV4dC10cmFuc2Zvcm06IHVwcGVyY2FzZTtcbn1cbiJdfQ== */