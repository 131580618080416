/* You can add global styles to this file, and also import other style files */

html,
body {
  margin: 0;
  font-family: "Titillium Web", sans-serif, Roboto, "Helvetica Neue", sans-serif;
}

h1,
h2,
h3,
h5,
h6 {
  margin: 8px 0;
  color: #2b0d61;
  font-weight: 600;
}

h4{
  color: #7587ba;
}

button.mat-flat-button {
  padding: 0px 32px 0px 32px;
  text-transform: uppercase;
}
